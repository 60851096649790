.ctf-header {
  padding-top: rem(62);
  padding-bottom: rem(200);
  background-color: #176C39;
  background-image: linear-gradient(to bottom, #176C39, #ebb326 80%, #0F391B);
  color: $white;
  overflow: hidden;
  position: relative;
  margin-bottom: rem(100);

  &::before {
    content: '';
    background: url(../../assets/img/images/preview2.jpg) no-repeat center center;
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 30px 10px rgba($black-dark-blue-2, 0.1);
    z-index: 2;
  }

  &-image {
    position: relative;

    img {
      margin: -110px 0 -220px -250px;
      max-width: none;
    }
  }

  @media (max-width: $md-max-width) {
    &-image {
      margin: 0 -500px;
      text-align: center;

      img {
        margin: -30px 0 -200px -30px;
      }
    }
  }

  &-white {
    background-image: none;
    background-color: $lighten-gray-8;

    &::after {
      box-shadow: 0 0 30px 10px rgba($black-dark-blue-2, 0.05);
    }
  }

  @media (max-width: $sm-max-width) {
    padding-top: rem(52);
  }

  @media (max-width: $xs-max-width) {
    padding-top: rem(42);
  }

  &-wrapper {
    position: relative;
    z-index: 2;
  }

  &-nav {
    margin-bottom: rem(125);

    @media (max-width: $md-max-width) {
      margin-bottom: rem(100);
    }

    @media (max-width: $sm-max-width) {
      margin-bottom: rem(75);
    }
  }

  &-buttons {
    @media (max-width: $md-max-width) {
      text-align: center;
    }
  }

  &-title {
    font-size: rem(60);
    font-weight: 900;
    color: $white;
    margin-bottom: rem(40);

    @media (max-width: $md-max-width) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
    }

    @media (max-width: $sm-max-width) {
      font-size: rem(55);
    }

    @media (max-width: $xs-max-width) {
      width: 100%;
    }

    &-black {
      color: $darken-purple;
    }

    &-inner {
      margin-bottom: rem(60);
    }
  }

  &-title-note {
    font-size: rem(18);
    line-height: rem(30);
    margin-bottom: rem(40);

    @media (max-width: $md-max-width) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }

    @media (max-width: $xs-max-width) {
      width: 100%;
    }

    p {
      margin-bottom: 0;
    }

    b {
      font-weight: 700;
    }
  }

  &-product-promo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-product-presentation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: rem(58);

    @media (max-width: $md-max-width) {
      margin-bottom: rem(40);
    }

    @media (max-width: $xs-max-width) {
      justify-content: center;
    }
  }

  &-product-logo {
    max-width: rem(75);
    margin-right: rem(28);

    @media (max-width: $xs-max-width) {
      margin-right: rem(10);
      max-width: rem(58);
    }
  }

  &-product-title {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: $darken-purple;
    font-weight: 700;
    margin-bottom: 0;
    font-size: rem(28);

    @media (max-width: $xs-max-width) {
      font-size: rem(18);
    }
  }

  &-product-name {
    font-weight: 900;
    font-size: rem(38);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @media (max-width: $xs-max-width) {
      font-size: rem(35);
    }

    .ctf-badge {
      font-size: rem(24);
      margin-left: rem(16);

      @media (max-width: $xs-max-width) {
        font-size: rem(18);
        margin-left: rem(11);
      }
    }
  }

  &-promo {
    // color: $purple-gray;
    color: $lighten-gray;
    font-size: rem(18);
    line-height: rem(30);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(35);

    @media (max-width: $md-max-width) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }

    @media (max-width: $xs-max-width) {
      width: 100%;
    }

    &-inner {
      margin-bottom: rem(110);
    }
  }
}
